<template>
  <my-dialog
    :title="`שינוי סיסמה ל${user.firstName} ${user.lastName}`"
    v-model="openModal"
  >
    <template v-slot:content>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <v-text-field
            :rules="[rules.min]"
            v-model="newPassword"
            counter="25"
            label="לשינוי סיסמה"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="changePassword"
        :disabled="!valid"
        v-text="'שמירת הסיסמה'"
        color="success"
      />
    </template>
  </my-dialog>
</template>
<script>
import MyDialog from "../generics/MyDialog.vue";
export default {
  name: "ResetPasswordByAdmin",
  components: { MyDialog },
  props: { value: Boolean, user: { type: Object, default: () => ({}) } },
  data() {
    return {
      newPassword: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "מינימום 6 תווים",
      },
    };
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    valid() {
      return this.newPassword.length > 5;
    },
  },
  methods: {
    async changePassword() {
      try {
        await this.$store.dispatch("admin/change-password/user", {
          _id: this.user._id,
          password: this.newPassword,
        });
        this.openModal = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
