<template>
  <v-container class="d-flex justify-center flex-column pa-10">
    <admin-date-table @showDialog="showDialog" />
    <admin-kid-dialog :show="show" @closeDialog="closeDialog" />
  </v-container>
</template>

<script>
import AdminDateTable from "@/components/tables/AdminDateTable.vue";
import AdminKidDialog from "@/components/dialogs/AdminKidDialog.vue";
export default {
  name: "AdminUserManager",
  components: {
    AdminDateTable,
    AdminKidDialog,
  },
  data() {
    return {
      show: false,
      menuStatus: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    openMenu() {
      this.menuStatus = !this.menuStatus;
    },
    showDialog() {
      this.show = true;
    },
    closeDialog() {
      this.$store.commit("kid/unset");
      this.show = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    menuStatus() {
      return console.log("openMenu");
    },
  },
  beforeCreate() {
    this.$store.dispatch("admin/user");
  },
};
</script>
<style lang="scss" scoped></style>
