<template>
  <v-row justify="center" v-if="$store.getters.kid">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialog"
      color="primary"
    >
      <v-card class="lightPurple">
        <v-toolbar flat color="lightPurple">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">
            פעולת של {{ $store.getters.kid.name }}
          </v-toolbar-title>
        </v-toolbar>
        <div class="container">
          <action-history :id="this.$store.getters.kid._id" />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ActionHistory from "@/views/ActionHistory.vue";
export default {
  components: { ActionHistory },
  name: "MyDialog",
  props: {
    show: Boolean,
  },
  data() {
    return {
      dialog: this.$props.show,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("closeDialog");
    },
  },
  watch: {
    show() {
      this.dialog = this.show;
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  max-width: 500px;
  padding-bottom: 20vh;
  border-radius: 30px 30px 30px 30px;
}
</style>
