<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="חיפוש"
        single-line
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="usersHeaders"
      :items="users"
      :search="search"
      item-key="email"
      show-expand
      @click:row="(item, slot) => handleClick(item, slot)"
    >
      <template v-slot:[`item.phone`]="{ item }">
        <a :href="`tel:+${item.phone}`">
          {{ item.phone }}
          <i class="fas fa-mobile"></i>
        </a>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <a :href="`mailto:${item.email}`">
          {{ item.email }}
          <i class="fas fa-mobile"></i>
        </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green"
          @click="resetPassword(item)"
          v-show="item.role !== 0"
        >
          mdi-lock-reset
        </v-icon>
        <v-icon
          color="black"
          @click="deleteUser(item)"
          v-show="item.role !== 0"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-1">
          <v-simple-table
            :items="item.kids"
            fixed-header
            v-if="item.kids.length"
            @click:row="handleClick"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-right">שם הילד</th>
                  <th class="text-right">סכום</th>
                  <th class="text-right">אייקון</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="i in item.kids"
                  :key="i._id"
                  @click="showDialog(i._id)"
                >
                  <td>{{ i.name }}</td>
                  <td>{{ i.balance }}</td>
                  <td>
                    <v-avatar size="30">
                      <v-img
                        :src="`/imgs/users/${i.color}.png`"
                        lazy-src="/person.png"
                      />
                    </v-avatar>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else class="center-all">אין ילדים</div>
        </td>
      </template>
    </v-data-table>
    <ResetPasswordByAdmin v-if="openDialog" v-model="openDialog" :user="user" />
  </v-card>
</template>

<script>
import ResetPasswordByAdmin from "@/components/dialogs/ResetPasswordByAdmin.vue";

export default {
  name: "AdminDateTable",
  components: { ResetPasswordByAdmin },
  data() {
    return {
      openDialog: false,
      user: null,
      search: "",
      usersHeaders: [
        {
          text: "שם פרטי",
          align: "start",
          value: "firstName",
        },
        {
          text: "שם משפחה",
          align: "start",
          value: "lastName",
        },
        { text: "מספר הילדים", value: "kidLength" },
        { text: "מספר טלפון", value: "phone" },
        { text: "אימייל", value: "email" },
        { text: "כתובת", value: "address" },
        { text: "פעולות", value: "actions" },
      ],
    };
  },
  methods: {
    async showDialog(id) {
      await this.$store.dispatch("user/get-kid", id);
      this.$emit("showDialog", true);
    },
    handleClick(expand, select) {
      select.expand(!select.isExpanded);
    },
    async deleteUser(user) {
      console.log(user);
      await this.$store.dispatch("user/delete", user.email);
    },
    resetPassword(user) {
      this.user = user;
      this.openDialog = true;
      console.log(user);
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("admin/user");
  },
  computed: {
    users() {
      if (this.$store.getters.users) {
        const usersMap = this.$store.getters.users.map((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            kidLength: user.kids.length,
            phone: user.phone,
            email: user.email,
            address: user.address,
            ...user,
          };
        });
        return usersMap;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.$store.commit("title/set", "ניהול משתמשים");
  },
  beforeDestroy() {
    this.$store.commit("title/set", "");
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>
